@font-face {
  font-family: "myGloryFont";
  src: url(../src/fonts/Gilroy-Black.ttf);
  font-weight: 800;
}
@font-face {
  font-family: "myGloryFont";
  src: url(../src/fonts/Gilroy-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: "myGloryFont";
  src: url(../src/fonts/Gilroy-Medium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: "myGloryFont";
  src: url(../src/fonts/Gilroy-Regular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: "myGloryFont";
  src: url(../src/fonts/Gilroy-SemiBold.ttf);
  font-weight: 600;
}

* {
  font-family: "myGloryFont";
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --theme: #cf126f;
  --main: #09061a;
   font-size: 10px;
}
ul{
  list-style: none;
}
.mx-right {
  margin-left: auto;
}
header.main_header {
  background: #ffffff;
}
.main_navbar {
  padding: 2rem 0;
}

.back_to_top a,
.back_to_top{
  display: inline-block;
}
.back_to_top{
  
  position: fixed;
  right: 30px;
  bottom: 80px;
  z-index: 11;

  
}
.back_to_top a svg{
  height: 50px;
  width: 50px;
  color: var(--theme);
}
a.navbar-brand {
  display: inline-block;
  margin: 0 0;
  width: auto;
  padding: 0;
}
img.logo {
  display: inline-block;
  margin: 0 0;
  width: 120px;
  max-width: 100%;
}
.secondary_logo{
  display: inline-block;
}
.main_menu {
  display: flex;
  list-style: none;
  padding: 0;
  align-items: center;
}
.main_menu li + li {
  margin-left: 4rem;
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.main_menu li a {
  font-size: 16px;
  line-height: 1.45;
  font-weight: 800;
  color: #1c1d3f;
  text-transform: uppercase;
  padding: 0;
  padding-right: 0;
  padding-left: 0;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -ms-transition: 0.5s all;
  -o-transition: 0.5s all;
  cursor: pointer;
}
.navbar-light .navbar-nav .nav-link.active {
  color: #ffffff;
  padding: 8px;
  background: #e62986;
  border: 1px solid #1c1d3f00;
  border-radius: 12px;
  font-weight: 600;
}
.main_menu li a.nav-link.reg_link,
.navbar-light .navbar-nav .nav-link.active.reg_link {
  padding: 10px 23px;
  background: #e62986;
  border-radius: 32px;
  display: inline-flex;
  padding-right: 23px;
  color: #fff;
  padding-left: 23px;
}
.banner_text_wrapper h1 {
  font-size: 8.3rem;
  line-height: 1.05;
  font-weight: 800;
  color: #cecfe9;
  text-transform: capitalize;
  padding: 0;
  margin-bottom: 0;
}
.banner_text_wrapper h1 span {
  display: block;
  font-size: 10rem;
}
.banner_text_wrapper h1 span:first-child {
  /** TEXT GRADIENT */
  color: #723184;
  background-image: -webkit-linear-gradient(90deg, #723184 23%, #4c4896 42%);
  background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.banner_text_wrapper h1 span:last-child {
  color: #b70561;
  background-image: -webkit-linear-gradient(90deg, #b70561 28%, #901e75 69%);
  background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
/* .banner_section {
  background: #e7e2ef;
} */
/* .home_banner_figure{
  position: relative;
  z-index: 1;
}
.home_banner_figure .vedio_banner{
  height: 100%;
  width: 100%;
} */
/* .home_banner_figcaption {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
} */
.global_section {
  padding: 10rem 0;
  background: rgb(238,174,202);
  background: linear-gradient(90deg, rgba(238,174,202,1) 0%, rgba(244,203,221,1) 0%, rgba(251,234,241,1) 36%, rgba(255,255,255,1) 67%, rgba(255,255,255,1) 84%);
}
/* .global_section h2 {
  font-size: 11rem;
  line-height: 1.05;
  font-weight: 800;
  color: #1c1d3e;
  text-transform: capitalize;
  padding: 0;
  margin-bottom: 0;
}
.global_section h2 span {
  color: #b90461;
  background-image: -webkit-linear-gradient(90deg, #b90461 34%, #4d4796 58%);
  background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.global_section h4 {
  font-size: 11rem;
  line-height: 1.05;
  font-weight: 800;
  color: #1c1d3e;
  text-transform: uppercase;
  padding: 0;
  margin-bottom: 0;
} */
/* .global_section h4, */
.confrence_community h4,
.about_confrence h4,
.timeline_section h4,
.journey_section h4 {
  font-size: 2.5rem;
  line-height: 1.2;
  font-weight: 500;
  color: #1c1d3e;
  text-transform: inherit;
  margin-bottom: 3.2rem;
}
/* .global_section p {
  font-size: 2.1rem;
  line-height: 1.2;
  font-weight: 500;
  color: #1c1d3e;
  margin-bottom: 0rem;
} */

.confrence_community {
  padding: 7rem 0;
  background: rgb(75, 73, 151);
  background: linear-gradient(90deg, rgba(238,34,136,0.22452731092436973) 0%, rgba(244,181,53,0.2049194677871149) 96%, rgba(244,181,53,0.3337710084033614) 100%);
}
.confrence_community h3,
.about_confrence h3,
.timeline_section h3,
.journey_section h3,
.eve_hosted h3 {
  font-size: 6rem;
  line-height: 1.05;
  font-weight: 800;
  color: #e62986;
  text-transform: capitalize;
  margin-bottom: 10px;
}
.confrence_community h3{
 color: var(--main);
}
.confrence_community h4 {
  margin-bottom: 0;
  font-weight: 600;
}
.home_slider {
  padding: 24px 0;
  background-color: #e7e2ef;
}
.strip_slider h2{
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
.strip_slider a{
  cursor: pointer;
  background: #fff;
  padding:10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius:10px;
}
.strip_slider a img{
 height: 70px;
}
.strip_slider .home_slider_carousel.owl-theme .owl-nav{
  left: -50px;
  z-index: -11;
  width: calc(100% + 100px);
}
.banner_section .home_slider_carousel {
  margin: 0;
}
.home_slider_carousel {
  max-width: 1400px;
  margin: 0 auto;
}

.home_slider_carousel .owl-dots {
  display: none;
}
.home_slider_carousel.owl-theme .owl-nav {
position: absolute;
top: 17%;
display: flex;
width: 100%;
justify-content: space-between;
 
}
.home_slider_carousel.owl-theme .owl-nav button {
  margin: 0 0;
}
.home_slider_carousel.owl-theme .owl-nav .owl-prev{
  text-align: start;
}
.home_slider_carousel.owl-theme .owl-nav .owl-next{
  text-align: end;
}
.home_slider_carousel.owl-theme .owl-nav button span {
  font-size: 3.8rem;
  font-weight: 500;
  cursor: pointer;
}
.home_slider_carousel.owl-theme .owl-nav [class*="owl-"]:hover {
  background: transparent;
  color: #b90461;
  text-decoration: none;
}
.home_slider .owl-carousel .owl-item img {
  display: block;
  width: 200px;
  max-width: 100%;
  margin: 0 auto;
 
}
.about_confrence {
  padding: 10rem 0;
}
/* .timeline_section h4 , */
.about_confrence h4
{
  font-family: "myballinger-font", sans-serif;
}
.about_confrence h3,
.journey_section h3,
.eve_hosted h3 {
  color: #1c1d3e;
}
.about_confrence h3 span,
.timeline_section h3 span,
.journey_section h3 span,
.eve_hosted h3 span {
  display: block;
  color: #b90461;
  background-image: -webkit-linear-gradient(90deg, #b90461 34%, #4d4796 58%);
  background-clip: text;

}
.about_session {
  padding: 4.82rem 3.22rem;
  background-color: #fff6f9;
  border-radius: 3.2rem;
  -webkit-border-radius: 3.2rem;
  -moz-border-radius: 3.2rem;
  -ms-border-radius: 3.2rem;
  -o-border-radius: 3.2rem;
  text-align: left;
  box-shadow: 13px 17px 45px 3px #cfcfd0;
  max-width: calc(100% - 14%);
  margin: 0 auto;
  cursor: pointer;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -ms-transition: 0.5s all;
  -o-transition: 0.5s all;
  height: 100%;
}
.about_session:hover {
  background-color: #f4f4ff;
}
.about_session h3 {
  font-size: 3.3rem;
  line-height: 1.2;
  font-weight: 700;
  color: #1c1d3e;
  text-transform: capitalize;
  margin-bottom: 2.4rem;
}
.about_confrence .about_session h3 span {
  color: #e62986;
  background: unset;
  -webkit-text-fill-color: unset;
}
.about_confrence .about_session:hover h3 span {
  color: #4b4997;
}
.about_session p {
  font-size: 19px;
  line-height: 1.45;
  font-weight: 500;
  color: #000;
}
.about_confrence h4,
.timeline_section h4,
.journey_section h4,
.eve_hosted h4 {
  margin-bottom: 7rem;
}
.timeline_section {
  padding: 10rem 0;
  background: rgb(238,174,202);
background: linear-gradient(90deg, rgba(238,174,202,1) 0%, rgba(244,203,221,1) 0%, rgba(251,234,241,1) 36%, rgba(255,255,255,1) 67%, rgba(255,255,255,1) 84%);
  text-align: center;
}
.timeline_section h3 {
  color: #1c1d3e;
}
.timeline_section h3 span {
  display: inline-block;
}
.cd-timeline-content h5,
.eve_hosted h4 {
  font-size: 2.7rem;
  line-height: 1.2;
  font-weight: 700;
  color: #000;
}
.cd-container {
  overflow: hidden;
}
.cd-timeline-content p {
  font-size: 2.2rem;
  line-height: 1.2;
  font-weight: 500;
  color: #000;
}
.cd-timeline-content.text-right {
  text-align: right;
}
.cd-timeline-content {
  cursor: pointer;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  transition: all 0.5s;
}
.cd-timeline-content:hover {
  background: rgb(102, 63, 143);
  background: linear-gradient(
    126deg,
    rgba(102, 63, 143, 1) 36%,
    rgba(160, 19, 108, 1) 100%
  );
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.cd-timeline-content:hover > h5 {
  color: #fff;
  background-clip: unset;
  -webkit-text-fill-color: unset;
  background: unset;
}
.cd-timeline-content:hover > p {
  color: #fff;
}
.journey_section {
  padding: 10rem 0;
  background-color: #f4f1f8;
  text-align: center;
}
.journey_section h3 span {
  display: inline-block;
}
.jaurney_txt {
  padding: 7rem 3.5rem;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3.2rem;
  -webkit-border-radius: 3.2rem;
  -moz-border-radius: 3.2rem;
  -ms-border-radius: 3.2rem;
  -o-border-radius: 3.2rem;
  box-shadow: 3px 5px 8px -3px #00000045;
  height: 100%;
}
.jaurney_txt h4 {
  font-size: 3.3rem;
  line-height: 1.2;
  font-weight: 500;
  color: #1c1d3e;
  margin-bottom: 0;
  display: block;
}
.jaurney_txt strong {
  font-weight: 700;
  display: block;
}
.eve_hosted {
  padding: 10rem 0;
  text-align: center;
  background: linear-gradient(90deg, rgba(238,174,202,1) 0%, rgba(244,203,221,1) 0%, rgba(251,234,241,1) 36%, rgba(255,255,255,1) 67%, rgba(255,255,255,1) 84%);
}
.eve_hosted h4 {
  margin-bottom: 7rem;
  font-weight: 500;
}
.cd-timeline-content h5 {
  background: linear-gradient(
    180deg,
    rgba(102, 63, 143, 1) 46%,
    rgba(160, 19, 108, 1) 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.conf_host_figure {
  display: flex;
  padding: 4.4rem 5rem;
  background: #fff;
  border-radius: 2.4rem;
  box-shadow: 14px 10px 43px -10px #00000075;
  max-height: 140px;
  align-items: center;
  justify-content: center;
  max-width: 90%;
  margin: 0 auto;
}
.j-center {
  justify-content: center;
}
.conf_host_figure img {
  max-height: 100px;
  width: auto;
  max-width: 100%;
}
.attendees_sec {
  padding: 10rem 0;
  background: rgb(75, 73, 151);
  background-color: var(--main);
  text-align: center;
}
.attendees_sec .owl-carousel .owl-stage{
 display: flex;
}

.attendees_sec .item{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.attendees_sec h3 {
  font-size: 6rem;
  line-height: 1.2;
  font-weight: 800;
  color: #ffdee4f0;
  margin-bottom: 8px;
  text-transform: capitalize;
}
.attendees_sec h3 span {
  color: #e62986;
}
.attendees_sec h4 {
  font-size: 2.2rem;
    line-height: 1.2;
    font-weight: 500;
    color: #fff;
    margin-bottom: 3rem;
    max-width: 1300px;
    margin: 3rem auto;
}
.review_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.review_wrapper > img {
  height: 100px;
  width: 100px;
  max-width: 100px;
  max-height: 100px;
}
.review_wrapper {
  margin-bottom: 2.4rem;
}
.review_detail {
  padding-left: 24px;
  text-align: left;
}
.attendees_sec .testimnial-figure {
  background: linear-gradient(180deg, rgba(47,42,73,1) 0%, rgba(9,6,26,1) 10%, rgba(47,44,64,1) 90%);
  padding: 4.8rem 3.2rem;
  height: 100%;
  display: flex;
  border-radius: 24px;
  margin: 10px;
  border-radius: 20px; 
  box-shadow: -2px -1px 1px 0px #979393;
}

.review_wrapper h4 {
  color: #e62986;
  font-size: 3rem;
  line-height: 1.2;
  font-weight: 800;
  text-transform: capitalize;
  margin: 0;
}
.review_wrapper h5 {
  color: #fff;
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 500;
  text-transform: capitalize;
}
.attendees_sec .owl-carousel .owl-nav button.owl-prev,
.attendees_sec .owl-carousel .owl-nav button.owl-next {
  height: 40px;
  width: 40px;
  display: inline-block;
  background: #e9e5ef;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.attendees_sec .owl-carousel .owl-nav button.owl-prev{
  position: absolute;
  left: -50px;
  top: -15px;
}
.attendees_sec .owl-carousel .owl-nav button.owl-next{
  position: absolute;
  right: -50px;
  top: -15px;
}
.attendees_sec .home_slider_carousel.owl-theme .owl-nav {
  z-index: 1;
  top: 50%;
}

.attendees_sec .home_slider_carousel.owl-theme .owl-nav button span {
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
}
.attendees_sec .home_slider_carousel.owl-theme .owl-nav [class*="owl-"]:hover {
  background: #fff;
  color: #b90461;
  text-decoration: none;
}

.attendees_sec .testimnial-figure p {
  color: #fff;
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 500;
  text-align: left;
}
.footer_section {
  padding: 40px 0;
  background-color: #fffdfd;
  border-top: 1px solid var(--main);
}
.footer_section .primery_footer{
  border-bottom: 1px solid var(--main);
}
.social_media{
  display: flex;
  padding: 0;
  margin: 2rem 0rem;
}
.social_media li+li{
  margin-left: 10px;
}
.social_media li a{
  background: #e52c88;
  display: inline-block;
  color: #fff;
  padding: 0px;
  font-size: 23px;
  border-radius: 33px;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.primery_footer .secondary_logo img{
  display: inline-block;
  width: 120px;
  max-width: 100%;
}
ul.social_list {
  list-style: none;
  padding: 0;

}
ul.social_list h5 { 
 font-size: 2.5rem;
 font-weight: 600;
}

ul.social_list a {
  font-size: 1.8rem;
  display: inline-block;
  font-weight: 600;
  padding: 0;
  color: var(--main);
}
ul.social_list a:hover {
  color: #e62986;
}
.secondary_footer {
  padding: 24px 0 0;
}
.the2conf_address{
  font-size: 16px;
  font-weight: 600;
  color: var(--main);
  position: relative;
}
.the2conf_address span{
  position: absolute;
  left: -24px;
}
.the2conf_address span svg{
  width: 20px;
  height: 20px;
  color: var(--main);
}
.condition_list {
  display: flex;
  padding: 0 0;
  justify-content: center;
  align-items: center;
}
/* .condition_list li + li {
    padding-left: 16px;
  } */
.condition_list a,
p.condition_list {
  font-size: 2rem;
  line-height: 1.45;
  text-transform: capitalize;
  color: #1c1d3e;
  font-weight: 500;
  margin: 0.5rem 0rem;
}
.condition_list a {
  display: inline-block;
}
p.brought {
  font-size: 16px;
  line-height: 1.45;
  color: #1c1d3e;
  font-weight: 400;
  text-align: right;
}
.unleasing_innovation .gallery_content{
  margin: 40px 0px;
  -webkit-box-shadow: -1px 4px 20px -11px rgba(0,0,0,1);
  -moz-box-shadow: -1px 4px 20px -11px rgba(0,0,0,1);
  box-shadow: -1px 4px 20px -11px rgba(0,0,0,1);
  padding: 15px;
  border-radius: 10px;
 }
 .unleasing_innovation .gallery_content img{
  height: 100%;
  object-fit: cover;
 }
 .across_sectors_banner figure{
    position: relative;
 }
 .across_sectors_banner .gallery_figure .gallery_banner_caption {
bottom: 50%;
position: absolute;
text-align: center;
-webkit-transform: translateY(50%);
transform: translateY(50%);
width: 100%;
}
.across_sectors_banner .gallery_banner_caption p {
font-size: 2rem;
font-weight: 600;
}
.across_sectors_banner .gallery_banner_caption h1 {
background-clip: text;
font-size: 8rem;
font-weight: 800;
color: #201e63;
font-family: "myGloryFont";
}
.heading_wrappping{
margin-top: 0px;
}
.across_sectors_banner .gallery_banner_caption .across_sectors_heading p{
font-size: 3rem;
}
.across_sectors_banner .gallery_banner_caption .across_sectors_heading{
color: #1c1d3e;
}
.across_sectors_content{
max-width: 960px;
margin: 0 auto;
}
.padding{
display: flex;
justify-content: center;
align-items: center;
}
.unleasing_innovation .view_more{
margin-top: 2rem;
padding: 0px;
}
.unleasing_innovation .view_more a{
background: #201e63;
display: inline-block;
padding: 8px 18px;
border-radius: 10px;
color: #ffff;
font-size: 16px;
font-weight: 600;
text-decoration: none;
}
.unleasing_innovation .title_heading {
  font-size: 4rem;
  font-weight: 800;
  margin-bottom: 2rem;
  color: #201e63;

}
.unleasing_innovation p.about_heading{
  font-size: 2rem;
  text-align: justify;
  font-weight: 500;
}

.btn-alumni {
  background: #201e63;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  display: inline-block;
  margin-top: 10px;
  border-radius: 10px;
  padding: 8px 16px;
  }

/************custom-css**********/
/* figure.conf_host_figure.cxo_figure {
  background: #ad97b6;
} */
.review_wrapper img {
  border-radius: 50%;
}
.gallery_banner .gallery_figure {
  position: relative;
}
.gallery_gallery .confrence_community p{
 font-size: 2rem;
 font-weight: 500;
}
.gallery_gallery .confrence_community h3{
 font-size: 5rem;
}
.gallery_gallery .confrence_community h4{
  margin-bottom: 2rem;
}
.gallery_gallery .confrence_community{
  background: #fff;
}
.gallery_banner .gallery_figure .gallery_banner_caption {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 50%;
  transform: translateY(50%);
}
 .gallery_banner_caption h1 {
  font-size: 4rem;
  line-height: 1.2;
  font-weight: 700;
  color: #fff;
  text-transform: capitalize;
}
.gallery_gallery .gallery_banner_caption p{
  color: #fff;
  font-size: 2.2rem;
  font-weight: 500;
}
.gallery_wrapper {
  max-width: 1400px;
  margin: 0 auto;
  padding: 5px;
  background-color: #4d4b99;
  margin-bottom: 32px;
}
.gallery_wrapper img {
  display: inline-block;
}
.gallery_banner .gallery_figure::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #231fc42b;
}
.gallery_wrappering{
  padding: 60px 0px;
}
.padding{
  display: flex;
  justify-content: center;
  align-items: center;
}
.gallery_wrappering .view_more a{
  background: #201e63;
  display: inline-block;
  padding: 10px  30px;
  border-radius: 10px;
  color: #ffff;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
}
.gallery_wrappering .gallery_content{
  padding: 10px;
  background: #f2f2f3;
  border-radius: 10px;
}
.gallery_wrappering .gallery_content:nth-child(even) .row,
.gallery_wrappering:nth-child(even) .gallery_content .row{
  flex-direction: row-reverse;
}
.gallery_wrappering .gallery_content
.gallery_detail {
  padding: 70px 0;
}
.gallery_detail p {
  font-size: 16px;
  line-height: 1.45;
  font-weight: 500;
  color: #1e1e1e;
  margin-bottom: 48px;
}
/* =============css-for-healthcare-page======25-07-2023============ */
.healthcare_banner .gallery_banner_caption h1{
  background-image: -webkit-linear-gradient(90deg, #b90461 34%, #4d4796 58%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 8rem;
  font-weight: 800;

}
.healthcare_banner .gallery_banner_caption  p{
  font-size: 2rem;
  font-weight: 600;
}
.healthcare_secondary_section{
  padding: 8rem 0rem;
}
.healthcare_secondary_content h2{
  font-size: 5rem;
  font-weight: 800;

}
.healthcare_secondary_content h2 span:last-child{
  background-image: -webkit-linear-gradient(90deg, #b90461 34%, #4d4796 58%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.healthcare_secondary_content h2 span:first-child{
  display: block;
  color: #1c1d3e;
}
.healthcare_secondary_content p {
  font-size: 2rem;
  margin-bottom: 2rem;
  font-weight: 500;
  text-align: justify;
}
.healthcare_secondary_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin-left: 4rem;
}
/******************healthcare-about-section******************/
.healthcare_about_section{
  background: linear-gradient(90deg, rgba(238,174,202,1) 0%, rgba(244,203,221,1) 0%, rgba(251,234,241,1) 36%, rgba(255,255,255,1) 67%, rgba(255,255,255,1) 84%);
  padding: 0;
}
.healthcare_about_section .speaker_image,.healthcare_about_section .speaker_image img {
  height: 100%;
  object-fit: cover;
}
.healthcare_about_section .healthcare_secondary_content{
  max-width: 700px;
  margin:  0px auto;
  padding: 5rem 0rem;
}
.past_participants,.image_gallery_section{
  padding: 4rem 0rem;
}
.image_gallery_section .past_logos img{
  width: 100%;
  height: 100%;
  padding: 4px;
  object-fit: cover;
}

.past_participants h4{
  margin-bottom: 0rem;
  font-size: 2rem;
}
.past_participants h3 {
  font-size: 5rem;
  line-height: 1.2;
  font-weight: 800;
  color: #fff;
  margin-bottom: 8px;
  text-transform: capitalize;
}
.healthcare_logos_section{
  background: #e7e2ef;
  padding: 4rem 0rem;
}
.healthcare_logos_section .past_logos{
 padding: 2rem;
}
.healthcare_logos_section .past_logos img{
  width: 100%;
}

/*****************HealthcareGallery********************/
.image_gallery_section .gallery_heading h3{
  font-size: 5rem;
  font-weight: 800;
  text-align: center;
  background-image: -webkit-linear-gradient(90deg, #b90461 34%, #4d4796 58%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.image_gallery_section .gallery_heading p{
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2rem;
  font-weight: 600;
}

------------------------------
.gallery_wrappring{
  width: 100%;
    max-width: 1000px;
    margin: 0 auto;
}

.paragragh_heading{
  font-size: 20px;
  font-weight: 500;
  line-height: 1.45;
  text-align: justify;
  margin-top: 20px;
  padding:0 0 20px 20px;
  
  
}
.headingg{
  font-size: 24px;
  font-weight: 600;
  padding: 0 0 20px 20px;
}
.heading_wrappping{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  flex-wrap: wrap;
  margin-top: 75px;
  padding: 40px;

}

.view_more{
  margin: 20px 0px;
  font-size: 15px;
  padding-left: 20px;

}
/* ===================== */
.home_figure{
  position: relative;
  margin: 0;
}

.home_figure .home_figure_caption{
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
  width: 100%;
  max-width: 100%;
}
.home_figure .home_figure_caption h1{
  color: var(--theme);
    font-size: 8rem;
    font-weight: 900;
    text-transform: uppercase;
}
.home_figure .home_figure_caption h1 p{
  font-size: 5rem;
  color: #fff;
  font-weight: 700;
}
.home_figure .home_figure_caption p{
  color: #fff;
  font-size: 2.4rem;
  font-weight: 500;

}
/* ======global-events======= */

.global_events{
  background: linear-gradient(90deg,#eeaeca,#f4cbdd 0,#fbeaf1 36%,#fff 67%,#fff 84%);
  padding: 10rem 0;
  position: relative;
}
.global_events::after{
  position: absolute;
  top: 100%;
  width: 70%;
  height: 5px;
  content: "";
  background: rgb(188,3,94);
  background: linear-gradient(0deg, rgba(188,3,94,1) 0%, rgba(90,69,145,1) 100%);
  left: 50%;
  transform: translateX(-50%);
}
.global_events .globel_events_content h2{
  font-size: 6rem;
  color: var(--main);
  text-align: center;
  font-weight: 900;
}
.global_events .globel_events_content  h2 span{
  display: block;
}
.global_events .globel_events_content  h2 span,
.events_hosted  h3 span,
.our_journey .jaurney_heading h3 span,
.confrence_community  h3 span
{
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  background-image: -webkit-linear-gradient(90deg,#b90461 34%,#4d4796 58%);
  color: #b90461;
 
 
} 
.global_events .globel_events_content p {
  font-size: 2.2rem;
  text-align: center;
  font-weight: 500;
  margin: 2rem 0rem;
}
.global_events .globel_events_content p  span{
  display: inline-block;
  font-weight: 600;
}
.globel_events_content{
  max-width: 1380px;
  margin: 0 auto;
}
.global_events .globel_events_content .about_events p{
  text-align: center;
 
}
/* =====new-home-page-css-add==== */
.events_hosted{
  padding: 5rem 0rem;
  background: #edebf7;
}
.events_hosted .card{
  background: transparent;
  border: none;
  box-shadow: none;
}
.events_hosted  h3 {
  font-size: 6rem;
  color: var(--main);
  text-align: center;
  font-weight: 900;
}

.events_hosted h4{
  font-size: 2.2rem;
  text-align: center;
  font-weight: 600;
  margin: 2rem 0rem;
}
.events_hosted .logo_website{
  max-width: 200px;
  margin: 0 auto;
  padding: 5rem 0rem;
}
.events_hosted .flip-card {
  background-color: transparent;
  width: 100%;
  height: 100%;
  perspective: 1000px;
}


.events_hosted .card p{
  text-align: left;
  padding: 1rem 0rem;
  font-size: 2rem;
  color: #78777c;
  font-weight: 500;
}
.card_vist {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0rem;
  border-top: 2px solid #4a4949;
  align-items: center;
}
.card_vist .Butn_type_card {
  font-size: 2.2rem;
  text-transform: capitalize;
  font-weight: 700;

}

.events_hosted .events_logos a.Butn_type_card_link{
 width: 40px;
 display: inline-block;
}
.events_hosted .flip_card_back .card_vist{
  border-top: 2px solid #fff;
}
.events_hosted .card_vist p{
  color: #fff;
  font-size: 2.2rem;
  text-transform: uppercase;
}
.card_vist a{
  background-color: #fff;
  padding: 8px 8px;
  cursor: pointer;
  text-decoration: none;
  color: var(--main);
  font-size: 25px;
  width: 35px;
  height: 35px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}



.events_hosted .flip_card_front {
  background-color: #09061a;
  color: white;
  padding: 2rem 2rem 0rem 2rem;
  border-radius: 2rem;

}

.events_hosted .flip_card_back {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  background: transparent;
  color: white;
  padding: 2rem 2rem 0rem 2rem;
  border-radius: 2rem;
  color: #fff;
  overflow: hidden;
  text-align: center;
  width: 100%; 
  top: 90%;
  -moz-transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
  -webkit-transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
  transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;

}
.flip-card:hover  .flip_card_back{
  top: 0;
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(15,32,61,1) 16%, rgba(211,40,79,1) 74%, rgba(242,24,78,1) 100%, rgba(0,0,0,1) 100%);
  transition: 1s all;
display: flex;
justify-content: center;
align-items: center;
 
}


.events_hosted .flip_card_back  .card{
  display: flex;
}
.events_hosted  .flip_card_back .card p {
  color: #fff;
  font-size: 1.8rem;
  line-height: 1.45;
}
.events_hosted .flip_card_back .card .card-body{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}
.events_hosted .events_logos{
  padding: 5rem 0rem 0rem 0rem;
}
.events_hosted .events_logos a{
  text-decoration: none;
  display: inline-block;
  width: 100%;
}
/* ========center_stage========= */
.center_stage{
  padding: 5rem 0rem;
  background-color: var(--main);
}
.center_stage .center_stage_heading h3{
  font-size: 6rem;
  color: #fff;
  text-align: center;
  font-weight: 900;
  margin-bottom: 2rem;
  color: #ffdee4f0;
}
.center_stage .center_stage_heading h3 span{
  color: #ce116e;
}
.center_stage .center_stage_heading   p{
  color: #fff;
  font-size: 2.2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 2rem;
}
.center_boxs{
  background: linear-gradient(180deg, rgba(47,42,73,1) 0%, rgba(9,6,26,1) 10%, rgba(47,44,64,1) 90%);
  padding: 5rem;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: -2px -1px 1px 0px #fff7f7;
}
.center_stage .center_boxs img{
  display: inline-block;
  margin-bottom: 2rem;
  width: auto;
  max-height: 77px;
  max-width: 90px;
}
.center_stage .center_boxs h5{
  color: #fff;
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 3rem;
}
.center_stage .center_boxs  p{
  color: #fff;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.55;
}
.center_stage .center_boxs h5 span{
  display: block;
  color: #ce116e;
}
/* ====our_journey======= */
.our_journey{
  padding: 6rem 0rem;
  background: rgb(238,34,136);
  background: rgb(238,34,136);
  background: linear-gradient(90deg, rgba(238,34,136,0.22452731092436973) 0%, rgba(244,181,53,0.2049194677871149) 96%, rgba(244,181,53,0.3337710084033614) 100%);
 
}
.our_journey .jaurney_heading h3{
  font-size: 6rem;
  color: var(--main);
  text-align: center;
  font-weight: 900;
  text-transform: capitalize;

}
.our_journey .jaurney_heading h4{
  color: #1c1d3e;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 3.2rem;
  text-transform: inherit;
}
.our_journey .jaurney_heading p{
font-size: 2.2rem;
text-align: center;
font-weight: 600;
max-width: 1300px;
margin: 4rem auto 6rem auto;
}


.our_journey  .jaurney_icons h4 {
color: var(--main);
display: block;
font-size: 2.5rem;
font-weight: 700;
line-height: 1.2;
margin-bottom: 0;
}
.our_journey .jaurney_icons h4 strong {
display: block;
color: var(--theme);
font-weight: 900;
font-size: 3.5rem;
}
.our_journey .jaurney_icons{
  padding: 4rem;
  border-radius: 30px;
  background: #f3f2ff;
  transform: skew(14deg, 349deg);
  margin: 3rem 0rem;
  box-shadow: 0px 1px 50px 0px #00000040;

}
.our_journey .colg:nth-child(2n-1) .jaurney_icons{
background: #fff;
transform: skew(351deg, 9deg);
margin: 3rem 0rem;
}

.our_journey .jaurney_icons .jaurney_icons_img{
margin-bottom: 10rem;
}

/*=====from_section============*/
.from_section{
padding: 6rem 0rem;
background: #edebf7;
}
.from_section .by_click{
  font-size: 15px;
  margin: 10px 0px;
  font-style: italic;
  font-weight: 400;

}
.main_from input,textarea,select{
  width: 100%;
  margin: 1rem 0rem;
  display: block;
  padding: 20px;
  outline: none;
  border: none;
  border-radius: 15px;
  font-size: 16px;
  color: var(--main);
  font-weight: 600;
  border: 1px solid var(--main);
}
.main_from select{
  border: 1px solid #000000;
    font-size: 18px;
    height: 70px;
    border-radius: 20px;
} 
.main_from  button[type="submit"]{
  background: rgb(188,3,94);
  background: linear-gradient(0deg, rgba(188,3,94,1) 0%, rgba(90,69,145,1) 100%);
  color: #fff;
  padding: 10px 20px;
  font-size: 20px;
  border-radius: 20px;
  font-weight: 700;
  outline: none;
  border: none;
  max-width: 200px;
}

/* ========events_scam_banner=========== */
.events_scam_banner .gallery_banner_caption h1{
  font-size: 5rem;
  color: #fff;
  -webkit-text-fill-color: #fff;
 }
 .events_scam_banner .gallery_banner_caption p{
   color: #fff;
 }
 .events_scam_section h2 {
   font-size: 3rem;
   font-weight: 800;
   margin: 1rem 0rem;
   text-align: left;
 }
 .events_scam_section .healthcare_secondary_content p a{
   color: #0d6efd;
 }
 .healthcare_secondary_content p.view_more{
  display: none;
 }
/*=========blog-page========== */
.blogs_section{
  padding: 6rem 0rem;
}
.blogs_section h2{
  font-size: 3rem;
  font-weight: 900;
  color: var(--theme);
  position: relative;
}
.blogs_section h2::after{
  position: absolute;
  top: 21px;
  right: 0;
  width: 90%;
  height: 2px;
  background-color: #6c6767;
  content: "";
}
.blogs_section h3{
  color: var(--main);
  font-size: 4rem;
  font-weight: 800;
  margin-bottom: 4rem;
}
.blogs_section .card_box_content h4{
  color: var(--main);
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
}
.blogs_section .card_box_content{
  padding: 2rem;
}
.blogs_section .card_box_content p{
 font-size: 1.8rem;
 font-weight: 500;
 margin-bottom: 2rem;
}
.blogs_section hr{
  margin: 4rem 0rem;
  height: 2px;
  width: 100%;
} 
.blogs_section p{
  font-size: 2.2rem;
  font-weight: 600;
}
.blogs_section a{
    font-size: 2rem;
    font-weight: 700;
    color: var(--main);
}
/* ==========single-blog-page======= */
.single_blog_heading h2{
    background: #f7ecec;
    padding: 2.5rem;
    margin-bottom: 6rem;
    border-radius: 20px;
    text-align: center;
    font-size: 3.5rem;
    font-weight: 800;
    position: relative;
    top: -60px;
    box-shadow: 0px 4px 12px #ffffff;
    color: var(--main);
}
.single_blog_heading h2 span{
  display: block;
  color: var(--theme);
  line-height: 1.55;
}
.single_blog_data .blog_image{
  margin-bottom: 5rem;
}
.single_blog_data .blog_content h3{
  font-size: 3.5rem;
  font-weight: 700;
  color: var(--main);
  margin-bottom: 2rem;
}
.single_blog_data .blog_content p{
  font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
}
/* ========== */
.blog_main_content{
 padding: 5rem 0rem;
}
.blog_main_content h4{
  font-size: 3rem;
  color: var(--theme);
  margin-bottom: 2rem;
  font-weight: 700;
}
.blog_main_content p{
 font-size: 2.2rem;
 font-weight: 500;
 margin-bottom: 3rem;
}
.review_cards{
  padding: 3rem 0rem;
}
.review_cards .testimonial-container {
  background-color: #fff;
  color: #000;
  border-radius: 20px;
  margin: 20px auto;
  padding: 30px;
  max-width: 100%;
  position: relative;
  border: 2px solid var(--bs-danger);
  min-height: 262px;
}
.review_cards  h3{
  font-size: 5rem;
  font-weight: 800;
  text-align: center;
  color: var(--main);
}


.review_cards .testimonial {
  line-height: 28px;
  text-align: justify;
}
.review_cards p.testimonial{
  font-size: 2rem;
  margin-bottom: 2rem;
  font-weight: 600;
}
.review_cards .user {
  display: flex;
  align-items: center;
  justify-content: start;
}

.review_cards .user .user-image {
  border-radius: 50%;
  height: 75px;
  width: 75px;
  object-fit: cover;
}

.review_cards .user .user-details {
  margin-left: 10px;
  text-align: start;
}

.review_cards .user .username {
  margin: 0;
  font-weight: 700;
  text-transform: capitalize;
  color: var(--bs-danger);
  font-size: 2.5rem;
}


.review_cards .user .role {
  font-weight: normal;
  font-size: 1.6rem;
  font-weight: 600;
}
p.testimonyal{
 text-align: end;
}
p.testimonyal i{
  font-size: 1.6rem;
  font-weight: 500;
}
.who_attend ul{
  display: flex;
      flex-wrap: wrap; /* Ensure items wrap if there are more than 4 */
      list-style-type: none;
      padding: 0;
      margin: 0;
      justify-content: center;
      align-items: center;
}
.who_attend ul li{
  flex: 0 1 25%; /* Each item takes 25% of the row */
  box-sizing: border-box;
  padding: 10px;
  
  text-align: center;

 }
 .who_attend ul li a img{
  width: 100%;
 }
 .who_attend ul li span{
  display: inline-block;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  box-shadow: 1px 2px 5px #a09393;
  border-radius: 20px;
 

 }
 .join_us_box{
  padding: 3rem 0rem;
 }
 .join_us_box h3{
  font-size: 4rem;
    font-weight: 800;
   margin-bottom: 2rem;
    background-image: -webkit-linear-gradient(90deg, #b90461 34%, #4d4796 58%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
 }
 .join_us_box p{
  font-size: 2rem;
  font-weight: 600;
  
 }
 .join_us_box .join_box{
  background: linear-gradient(90deg, rgba(238, 34, 136, 0.22452731092436973) 0%, rgba(244, 181, 53, 0.2049194677871149) 96%, rgba(244, 181, 53, 0.3337710084033614) 100%);
  border-radius: 30px;
 }
 .attendee-reviews_banner h1{
  font-size: 5rem;
  font-weight: 800;
  text-align: center;
  background-image: -webkit-linear-gradient(90deg, #b90461 34%, #4d4796 58%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
 }
 .attendee-reviews_banner .gallery_banner_caption p{
  color: #4d4796;
 }

 .join_text{
 padding:  5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
 }
 /* .image_gallery_section .past_logos img{

 } */
/************custom-css-end**********/
/************media-css-start**********/
@media screen and (min-width: 1700px) {
  .container {
    max-width: 1640px;
    margin: 0 auto;
  }
 
}
@media screen and (max-width: 1700px) {
  .events_hosted .flip_card_back .card p {
    color: #fff;
    font-size: 1.8rem;
    line-height: 1.40;
}
 
}

@media screen and (max-width: 1500px) {
  :root {
    font-size: 8px;
  }
 
  .global_section,
  .about_confrence,
  .timeline_section,
  .journey_section,
  .eve_hosted,
  .attendees_sec {
    padding: 7rem 0;
  }
  .banner_text_wrapper h1,
  .home_figure .home_figure_caption h1 {
    font-size: 7rem;
  }
  .banner_text_wrapper h1 span,
  .global_section h2 {
    font-size: 7rem;
  }
  .about_confrence h4,
  .timeline_section h4,
  .eve_hosted h4 {
    margin-bottom: 6rem;
  }
  .about_session {
    max-width: calc(100% - 6%);
  }
  .about_session h3 {
    font-size: 3rem;
    line-height: 1;
    margin-bottom: 2rem;
  }
  .jaurney_txt {
    padding: 4rem 15px;
  }
  .conf_host_figure {
    padding: 2.4rem 3rem;

    max-height: 120px;
  }
  .conf_host_figure img {
    max-height: 40px;
  }
  .home_slider_carousel.home_slider_attendee {
    max-width: 1140px;
  }
  .jaurney_txt {
    height: 100%;
  }
  .healthcare_about_section .healthcare_secondary_content{
    max-width: 600px;
  }
  
}


@media screen and (max-width: 1250px) {
.attendees_sec .owl-carousel .owl-nav button.owl-prev{
  left: -5px;
}
.attendees_sec .owl-carousel .owl-nav button.owl-next{
  right: -5px;
}
.healthcare_about_section .healthcare_secondary_content{
  max-width: 500px;
}
}
@media screen and (max-width: 1199px) {
  :root {
    font-size: 8px;
  }
 
  .banner_text_wrapper h1 {
    font-size: 6rem;
  }
  .banner_text_wrapper h1 span,
  .global_section h2 {
    font-size: 8rem;
  }
  figure.home_banner_figure > img {
    max-height: 200px;
    width: auto;
    margin: 0 auto;
    display: block;
  }
  
  .global_section,
  .about_confrence,
  .timeline_section,
  .journey_section,
  .eve_hosted,
  .attendees_sec,
  .confrence_community {
    padding: 5rem 0;
  }
  .global_section p {
    line-height: 1.45;
    text-align: justify;
  }
  .cd-timeline-content.text-right {
    text-align: left;
  }
  .cd-timeline-img {
    left: 16px;
    width: 22px;
    height: 22px;
    box-shadow: 0 0 0 5px #fff, inset 0 2px 0 rgb(0 0 0 / 8%),
      0 3px 0 4px rgb(0 0 0 / 5%);
  }
  .jaurney_txt h4 {
    font-size: 3rem;
  }
  .review_wrapper > img {
    height: 100px;
    width: 100px;
    max-width: 65px;
    max-height: 65px;
  }
  .attendees_sec .owl-carousel .owl-item {
    padding: 0 21px;
  }
  .review_wrapper h4 {
    font-size: 2.7rem;

    font-weight: 700;
  }
  .review_wrapper h5 {
    font-size: 2.3rem;
  }
  .review_wrapper {
    margin-bottom: 16px;
  }
  .review_detail {
    padding-left: 16px;
  }
  .attendees_sec .owl-carousel .owl-nav button.owl-prev,
  .attendees_sec .owl-carousel .owl-nav button.owl-next {
    height: 40px;
    width: 40px;
  }

  .home_slider_carousel.owl-theme .owl-nav .owl-prev{
   left: 0;
  }
  .healthcare_about_section .healthcare_secondary_content {
    max-width: 400px;
}
.attendees_sec h3,
.center_stage .center_stage_heading h3,
.events_hosted h3,
.global_events .globel_events_content h2,
.banner_text_wrapper h1, .home_figure .home_figure_caption h1{
  font-size: 5rem;
}
.events_hosted .flip_card_back .card p{
  font-size: 2rem;
}
.events_hosted .flip_card_back .card{
  display: flex;
  height: 100%;
  align-items: center;
}
.our_journey .jaurney_icons{
  padding: 3rem;
}
.our_journey .jaurney_icons h4{
  font-size: 2rem;
}
.our_journey .jaurney_icons h4 strong{
  font-size: 2.5rem;
}
.our_journey .jaurney_icons .jaurney_icons_img  img{
  width: 50px;
}
.attendees_sec .owl-carousel .owl-nav button.owl-next{
  right: 0;
}
.social_media{
  margin: 1rem 0rem;
}
.social_media li a {
  font-size: 22px;
  height: 40px;
  width: 40px;
}
.events_hosted .events_logos .row .col{
  flex: auto;
  padding: 10px;
  width: 50%;
  flex: inherit;
  margin: 0 auto;
}
.events_hosted .flip_card_back .card .card-body{
  justify-content: space-around;
}
}
@media screen and (max-width: 1024px) {
  :root {
    font-size: 8px;
  }
  .home_figure .home_figure_caption{
    top: 30%;
  }
  .home_figure .home_figure_caption h1{
    font-size: 6rem;
  }
  .headingg,
  .paragragh_heading{
    padding: 0;
  }
}
@media screen and (max-width: 991px) {
  :root {
    font-size: 8px;
  }
  .main_menu li a {
    font-weight: 600;
  }
  .who_attend ul li{
    flex: 0 1 33.33%!important; 

  }
  /* .events_hosted .flip_card_back{
    height: 100%;
    top: 0;
  } */
  /* .events_hosted .events_logos .row .col{
    width: 50%;
  } */
  .strip_slider .home_slider_carousel.owl-theme .owl-nav {
    left: -20px;
    z-index: -11;
    width: calc(100% + 40px);
}
.gallery_gallery .confrence_community h3,
.review_cards h3,.image_gallery_section .gallery_heading h3{
  font-size: 4rem;
 }
  .main_menu li + li {
    margin-left: 0rem;
    margin-top: 1rem;
  }
  .main_menu li{
    border-bottom: 1px solid gray;
    display: block;
    width: 100%;
  }
  .navbar-light .navbar-nav .nav-link.active{
    border-radius: 0px;
  }
  .view_more{
    text-align: center;
  }
  .main_menu li a.nav-link.reg_link {
    padding: 5px 12px;
  }
  .banner_text_wrapper h1 {
    font-size: 3rem;
  }
  .banner_text_wrapper h1 span,
  .global_section h2 {
    font-size: 6.5rem;
  }
  .confrence_community h3,
  .about_confrence h3,
  .timeline_section h3,
  .journey_section h3,
  .eve_hosted h3,
  .attendees_sec h3 {
    font-size: 3rem;
  }
  
  .attendees_sec .owl-carousel .owl-nav button.owl-prev, 
  .attendees_sec .owl-carousel .owl-nav button.owl-next{
    position: absolute;
   
  }
  .attendees_sec .owl-carousel .owl-nav button.owl-prev{
    left: 0;
    top: -15px;
  }
  .attendees_sec .owl-carousel .owl-nav button.owl-next{
    right: 11px;
    top: -15px;
  }
  .about_session {
    max-width: calc(100% - 0%);
    padding: 24px 16px;
  }
  .about_session h3 {
    font-size: 2.8rem;
    line-height: 1.2;
  }
  .about_session p {
    font-size: 16px;
  }
  .cd-timeline-img {
    left: 20px;
    width: 16px;
    height: 16px;
  }
  .about_confrence h4,
  .timeline_section h4,
  .journey_section h4,
  .eve_hosted h4 {
    margin-bottom: 5rem;
  }
  .global_section h4,
  .confrence_community h4,
  .about_confrence h4,
  .timeline_section h4,
  .journey_section h4 {
    font-size: 2.4rem;

    margin-bottom: 2.4rem;
  }
  .jaurney_txt h4 {
    margin-bottom: 0rem;
  }
  button.toggle_bar.navbar-toggler.collapsed {
    color: #fff;
    height: 25px;
    width: 35px;
    background-color: #e7e2ef;
  }
  span.navbar-toggler-icon {
    width: 20px;
    height: 20px;
  }
  .main_menu {
    display: flex;
    list-style: none;
    align-items: start;
    justify-content: start;
    padding: 16px 0;
    flex-wrap: wrap;
  }
  .container {
    padding: 0 15px;
  }
  ul.social_list a {
    font-size: 19px;
  }
  .my_nav_collapse {
    padding-top: 16px;
  }
  .across_sectors_banner .gallery_figure img,
  .gallery_banner .gallery_figure img{
    height: 50vh;
    object-fit: cover;
  }
  .healthcare_banner .gallery_banner_caption p{
    font-size: 3rem;
  }
  .healthcare_secondary_content h2{
    font-size: 3rem;
  }
  .healthcare_about_section .healthcare_secondary_content {
    max-width: 300px;
}
.healthcare_secondary_content{
  margin-left: 0px;
}
  /* .main_menu {
    border-top: 1px solid #e9ecef;
  } */
  
  .home_figure .vedio_banner {
    height: 45vh;
    object-fit: cover;
}
.footer_section .primery_footer{
  text-align: center;
}
.social_media{
  justify-content: center;
}
ul.social_list h5{
  font-size: 2.2rem;
}
ul.social_list{
  text-align: start;
}
ul.social_list a {
  font-size: 15px;
  font-weight: 600;
}
.the2conf_address{
 font-size: 15px;
 }
}
@media screen and (max-width: 767px) {
  .banner_text_wrapper {
    width: 100%;
    justify-content: center;
  }
  .who_attend ul li{
    flex: 0 1 50%!important; 

  }
  figure.home_banner_figure > img {
    max-height: 150px;
    margin: 32px auto 0;
    display: block;
  }
  .home_figure .home_figure_caption h1{
    font-size: 5rem;
  }
  .home_figure .home_figure_caption p br{
    display: none;
  }
  .add-space{
    margin-bottom: 20px;
  }
  .condition_list a,
  p.condition_list {
    font-size: 14px;
    line-height: 1.2;
    font-weight: 400;
    text-align: center;
  }
  .our_journey .colg:nth-child(2n-1) .jaurney_icons,
.our_journey .jaurney_icons{
  transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
}
  p.brought {
    font-size: 14px;
    line-height: 1.2;
    font-weight: 400;
    text-align: center;
  }
  .footer_section {
    padding: 16px 0;
   
  }

  .secondary_footer {
    padding: 12px 0 0;
  }

  .cd-timeline-content {
    margin-left: 43px;
    margin-right: 0;
  }
  .about_confrence h4 br{
    display: none;
  }
  .healthcare_about_section .healthcare_secondary_content {
    max-width: 500px;
}
.events_hosted .logo_website{
  max-width: 150px;
}
.center_stage .center_boxs img{
  max-width: 60px;
}

.home_figure .vedio_banner{
  height: 40vh;
  object-fit:cover;
}
.events_hosted .flip_card_back .card p{
 font-size: 2rem;
}
ul.social_list{
  text-align: center;
  margin: 10px 0px;
}
.the2conf_address span{
  position: static;
}
.past_participants h4 br{
  display: none;
}
/* .strip_slider .home_slider_carousel.owl-theme .owl-nav {
  left: -32px;
  z-index: -11;
  width: calc(100% + 60px);
} */
}
@media screen and (max-width: 575px) {
  .mb-sm-5.mb-col-5 {
    margin-bottom: 16px;
  }
 
  .main_menu li a {
    font-size: 15px;
  }
  .review_wrapper{
    flex-direction: column;
  }
  .review_wrapper h4{
    text-align: center;
  }
  .events_hosted .events_logos .row .col{
    width: 100%;
  }
  .banner_text_wrapper h1 span,
  .global_section h2 {
    font-size: 2.5rem;
  }
  figure.home_banner_figure > img{
    max-height: 100px;
  }
  .global_section h2{
    margin-bottom: 1rem;
  }
  .global_section p{
    text-align: justify;
  }
  .confrence_community h3, .about_confrence h3, .timeline_section h3, .journey_section h3, .eve_hosted h3, .attendees_sec h3{
    font-size: 4rem;
    text-align: center;
  }
  ul.social_list a{
    font-size: 16px;
  }
  .strip_slider .home_slider_carousel.owl-theme .owl-nav{
    display: none;
  }
  /* ==========Healthcare-page================ */
  .healthcare_banner .gallery_banner_caption h1{
    font-size: 5rem;
    font-weight: 800;
  }
  .healthcare_secondary_content h2 {
    font-size: 4rem;
    font-weight: 700;
    text-align: center;
   
}
.healthcare_secondary_section .speaker_image{
  padding: 5rem;
}
.healthcare_secondary_content{
  margin-left: 0rem;
}
.healthcare_about_section .healthcare_secondary_content{
  margin: 0 auto;
  padding: 3rem;
}
.blank_div{
  display: none;
}
.healthcare_logos_section .past_logos {
  padding: 2rem;
  width: 200px;
  margin: 1rem auto;
  max-width: 100%;
  border: 1px solid #cfcfcf;
}
.image-colmn{
 justify-content: center;
 width: 100%;
 padding: 0rem;
}
.across_sectors_banner .gallery_banner_caption h1{
  font-size: 5rem;
}
.home_figure .home_figure_caption h1 p {
  font-size: 3rem;
 
}
.home_figure img{
  height: 40vh;
  object-fit: cover;
}
.home_figure video{
  width: 100%;
} 
.home_figure .home_figure_caption h1 {
  font-size: 4rem;
}
.home_figure .home_figure_caption p{
  font-size: 2.2rem;
}
.home_figure .home_figure_caption p br{
 display: none;
}
.global_events .globel_events_content h2,
.events_hosted h3,
.center_stage .center_stage_heading h3,
.our_journey .jaurney_heading h3{
  font-size: 4rem;
}
.our_journey .colg:nth-child(2n-1) .jaurney_icons,
 .our_journey .jaurney_icons {
  text-align: center;
}


.primery_footer .footer_logo{
  text-align: center;
}
.social_media li a{
  width: 35px;
  height: 35px;
}
.back_to_top {
  position: fixed;
  right: 8px;
  bottom: 0px;
 
}
.heading_wrappping{
  padding: 20px;
}
.who_attend ul li{
  flex: 0 1 100%!important; 

}
}


@media screen and (max-width: 400px) {

  .global_events .globel_events_content h2, .events_hosted h3, .center_stage .center_stage_heading h3, .our_journey .jaurney_heading h3,
  .confrence_community h3, .about_confrence h3, .timeline_section h3, .journey_section h3, .eve_hosted h3, .attendees_sec h3 {
    font-size: 3rem;
}
}
